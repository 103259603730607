"use client";

import { useMainLayout } from "@/app/context/mainLayoutProvider";
import React, { useEffect, useState } from "react";
import LinkItem from "./LinkItem";
import { mdBreakPoint, navItems, screenInnerWidth } from "@/lib/utils";
import { useGlobalStore } from "@/app/context/globalStoreProvider";

type Props = {
  isLoggedIn?: boolean;
};

const MiddleComponent: React.FC<Props> = ({ isLoggedIn }) => {
  const { renderMiddleContent } = useMainLayout();
  const { user, isEventPage } = useGlobalStore();

  const [isLessThanMd, setIsLessThanMd] = useState(
    screenInnerWidth < mdBreakPoint
  );

  useEffect(() => {
    const handleResize = () => {
      setIsLessThanMd(window.innerWidth < mdBreakPoint);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (renderMiddleContent) return renderMiddleContent;
  if (!isLoggedIn) {
    return null;
  }
  return (
    <div className="flex h-full w-full items-center justify-center gap-6 text-xl">
      {!isLessThanMd && user?.status === "EMAIL_SYNCED" && !isEventPage
        ? navItems.map((item) => (
            <LinkItem path={item.href} key={item.key}>
              {item.title}
            </LinkItem>
          ))
        : null}
    </div>
  );
};

export default MiddleComponent;
