"use client";

import { signOut, useSession } from "next-auth/react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdownMenu";
import { LogOut, Settings, Gift } from "lucide-react";
import { Session } from "next-auth";
import { usePostHog } from "posthog-js/react";

import { useMainLayout } from "@/app/context/mainLayoutProvider";
import { useGlobalStore } from "@/app/context/globalStoreProvider";
import { useRouter } from "next-nprogress-bar";
import { cn, mdBreakPoint, navItems, screenInnerWidth } from "@/lib/utils";
import { useEffect, useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { GoogleAuthModal } from "@/components/modal/googleAuthModal";

type Props = {
  user?: Session["user"];
  isLoggedIn?: boolean;
};

const RightContainer: React.FC<Props> = ({ user, isLoggedIn }) => {
  const posthog = usePostHog(); // Use the PostHog hook
  const router = useRouter();
  const { isWebView, renderMiddleContent, logoOnlyInheader } = useMainLayout();
  const [collapseMenu, setCollapseMenu] = useState(false);
  const [googleAuthModalOpen, setGoogleAuthModalOpen] = useState(false);
  const {
    userCreditsData: { totalCashValueString },
    user: userData,
    isEventPage,
  } = useGlobalStore();
  const [isLessThanMd, setIsLessThanMd] = useState(
    screenInnerWidth < mdBreakPoint
  );
  const session = useSession();

  useEffect(() => {
    const handleResize = () => {
      setIsLessThanMd(window.innerWidth < mdBreakPoint);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = () => {
    // Reset PostHog user state
    if (posthog) {
      posthog.reset();
    }
    // Then sign out
    signOut({ callbackUrl: "/api/user/logout" });
  };

  const onProfileClick = () => {
    router.push("/profile?section=travel-profile");
  };

  const onGondolaRewardsClick = () => {
    router.push("/profile?section=gondola-rewards");
  };

  useEffect(() => {
    if (Boolean(renderMiddleContent)) {
      setCollapseMenu(true);
    } else {
      setCollapseMenu(false);
    }
  }, [renderMiddleContent]);

  const showNavDropdown = useMemo(() => {
    return isLoggedIn && (collapseMenu || isLessThanMd);
  }, [isLoggedIn, collapseMenu, isLessThanMd]);

  const setExtraWidth = useMemo(() => {
    return !(logoOnlyInheader
      ? true
      : Boolean(session?.data?.user.id) && collapseMenu && isLessThanMd);
  }, [collapseMenu, isLessThanMd, logoOnlyInheader, session?.data?.user.id]);

  return (
    <div className={cn("flex items-center gap-2 md:justify-end lg:gap-4")}>
      {isLoggedIn ? (
        <>
          <div className="flex items-center justify-center gap-3">
            <DropdownMenu>
              <DropdownMenuTrigger
                className={
                  setExtraWidth ? "min-w-[100px] sm:min-w-[200px]" : ""
                }
              >
                {user?.image ? (
                  <div className="ml-auto flex w-fit flex-col items-center justify-center text-center">
                    <div className="relative h-10 w-10 min-w-[40px]">
                      <img
                        src={user.image}
                        alt="profile"
                        className="h-full w-full rounded-full object-cover"
                      />
                    </div>
                    {totalCashValueString !== "$0" && (
                      <span className="line-clamp-1 text-left text-xs font-medium text-primary-gold">
                        {totalCashValueString}
                        <span className="hidden sm:inline"> in points</span>
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="h-10 w-10 rounded-full bg-zinc-300 sm:h-12 sm:w-12" />
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {showNavDropdown && userData?.status === "EMAIL_SYNCED" ? (
                  <>
                    {navItems.map((item) => (
                      <DropdownMenuItem
                        key={item.key}
                        onClick={() => router.push(item.href)}
                      >
                        {item.icon}
                        {item.title}
                      </DropdownMenuItem>
                    ))}
                    <div className="mx-auto h-px w-[90%] bg-white/15" />
                  </>
                ) : null}
                {userData?.status === "EMAIL_SYNCED" && !isEventPage && (
                  <>
                    <DropdownMenuItem onClick={onGondolaRewardsClick}>
                      <Gift className="mr-2 h-4 w-4" />
                      Earn $50
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={onProfileClick}>
                      <Settings className="mr-2 h-4 w-4" />
                      Settings
                    </DropdownMenuItem>
                  </>
                )}
                <DropdownMenuItem onClick={handleLogout}>
                  <LogOut className="mr-2 h-4 w-4" />
                  Logout
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </>
      ) : (
        !isWebView && (
          <>
            <GoogleAuthModal
              headerText="Login"
              open={googleAuthModalOpen}
              onOpenChange={setGoogleAuthModalOpen}
            />
            <Button
              variant="outline"
              className="rounded-full text-xs sm:text-base"
              onClick={() => setGoogleAuthModalOpen(true)}
            >
              Login
            </Button>
          </>
        )
      )}
    </div>
  );
};

export default RightContainer;
